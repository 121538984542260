//----------------------------------------------------/
// The block number above each block
//----------------------------------------------------/
.block-number {
  $color: #191919;

  text-align: center;
  font-weight: bold;
  font-size: 0.8125em;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $color;
  //opacity: 0.8;
  transition: 0.3s ease-out;

  &:hover {
    opacity: 1;
  }

  &::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 6px;
    margin-bottom: 2px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid $color;
  }

  a:hover,
  a:focus {
    color: $color;
  }
}


//----------------------------------------------------/
// Make some change to components in demo
//----------------------------------------------------/
.demo-section .main-content .section,
.demo-topbar .main-content .topbar,
.demo-navbar .main-content .navbar,
.demo-header .main-content .header,
.demo-footer .main-content .footer {
  margin: 30px 20px 100px;
  border: 1px solid #f5f6f7;
  box-shadow: 0 0 15px rgba(0,0,0,0.05);
}

.demo-topbar .main-content .topbar,
.demo-navbar .main-content .navbar {
  position: static;
  margin: 0;
}


//----------------------------------------------------/
// Index cover
//----------------------------------------------------/
.sample-blocks {

  img {
    border-radius: 0.25rem;

    &.aos-animate {
      opacity: 0.5 !important;
    }

    &:hover {
      opacity: 1 !important;
      transition: 0.7s !important;
    }
  }

  a {
    position: absolute;
  }

  a:nth-child(1) { animation: wobble 4s infinite; right: 15px; bottom: -20px; }
  a:nth-child(2) { animation: wobble 8s infinite; right: 320px; bottom: 30px; }
  a:nth-child(3) { animation: wobble 5s infinite; right: 0px; bottom: 190px; }
  a:nth-child(4) { animation: wobble 6s infinite; right: 280px; bottom: 180px; }
  a:nth-child(5) { animation: wobble 9s infinite; right: 270px; bottom: 320px; }
  a:nth-child(6) { animation: wobble 7s infinite; right: 20px; bottom: 370px; }
  a:nth-child(7) { animation: wobble 5s infinite; right: 200px; bottom: 470px; }
  a:nth-child(8) { animation: wobble 8s infinite; right: 10px; bottom: 470px; }
  a:nth-child(9) { animation: wobble 9s infinite; right: 0px; bottom: 570px; }

}

@keyframes wobble {
  0%   { transform: rotate(-1deg) translate(-5px, -5px); }
  50%  { transform: rotate(1deg) translate(5px, 5px); }
  100% { transform: rotate(-1deg) translate(-5px, -5px); }
}

//----------------------------------------------------/
// Footer responsive
//----------------------------------------------------/
@media (max-width: 400px) {
  body > .footer {
    .social a:last-child,
    .nav a:last-child {
      display: none;
    }
  }
}

//----------------------------------------------------/
// Tinkerware theme
//----------------------------------------------------/

.btn {
  background-color: #E0E9FF;
  border-color: #E0E9FF;
  border-radius: 5px;
  color: #0A47FF;
  font-family: 'Work Sans', sans-serif;
  font-size: 1.33em;
  font-weight: 700;
  &:hover {
    background-color: #0A47FF;
    border-color: #0A47FF;
    color: #FFFFFF;
  }
}

.lead {
  color: #818181;
}

.nav-navbar .nav-link.active {
    border-bottom: 2px solid rgba(10,71,255,0.6);
}

html {
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
}

.partner-sm img {
  height: 30px;
}

.full-width {
  width: 100%;
}

.text-justified {
  text-align: justify;
}

.padding-bottom-2em {
  padding-bottom: 2em;
}

.padding-top-2em {
  padding-top: 2em;
}

.westrum-section {
  & > p {
    padding-bottom: 2em;
  }
  & > p:first-child {
    padding-top: 2em;
  }
}

.hide-section {
  display: none;
}

//----------------------------------------------------/
// Calendly widget
//----------------------------------------------------/

.calendly-inline-widget, .calendly-badge-widget, .calendly-overlay {
  font-size: 16px;
  line-height: 1.2em;

  * {
    font-size: 16px;
    line-height: 1.2em;
  }
}

.calendly-inline-widget iframe, .calendly-badge-widget iframe, .calendly-overlay iframe {
  display: inline;
  width: 100%;
  height: 100%;
}

.calendly-popup-content {
  position: relative;

  &.calendly-mobile {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }
}

.calendly-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 9999;
  background-color: #a5a5a5;
  background-color: rgba(31, 31, 31, 0.4);

  .calendly-close-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .calendly-popup {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    width: 80%;
    min-width: 900px;
    max-width: 1000px;
    height: 90%;
    max-height: 680px;

    .calendly-popup-content {
      height: 100%;
    }
  }

  .calendly-popup-close {
    position: absolute;
    top: 25px;
    right: 25px;
    color: #fff;
    width: 19px;
    height: 19px;
    cursor: pointer;
    background: url(//assets.calendly.com/assets/external/close-icon-77963382ab339573bb06b204699d4adf5ebbcbaaa51784f1d9f4b611723b42b0.svg) no-repeat;
    background-size: contain;
  }
}

@media (max-width: 975px) {
  .calendly-overlay .calendly-popup {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: none;
    transform: none;
    width: 100%;
    height: auto;
    min-width: 0;
    max-height: none;
  }
}

@media (max-width: 975px) {
  .calendly-overlay .calendly-popup-close {
    top: 15px;
    right: 15px;
  }
}

.calendly-badge-widget {
  position: fixed;
  right: 20px;
  bottom: 15px;
  z-index: 9998;

  .calendly-badge-content {
    display: table-cell;
    width: auto;
    height: 45px;
    padding: 0 30px;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 2px 5px;
    font-family: sans-serif;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 14px;
    color: #fff;
    cursor: pointer;

    &.calendly-white {
      color: #666a73;
    }

    span {
      display: block;
      font-size: 12px;
    }
  }
}

.calendly-spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  z-index: -1;

  > div {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #e1e1e1;
    border-radius: 50%;
    vertical-align: middle;
    -webkit-animation: calendly-bouncedelay 1.4s infinite ease-in-out;
    animation: calendly-bouncedelay 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .calendly-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .calendly-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

@-webkit-keyframes calendly-bouncedelay {
  0%,80%,100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes calendly-bouncedelay {
  0%,80%,100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}