@font-face {
	font-family: 'Dosis';
	font-style: normal;
	font-weight: 200;
	font-display: auto;
	src: url(../fonts/Dosis-normal-200.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Dosis';
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(../fonts/Dosis-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Dosis';
	font-style: normal;
	font-weight: 500;
	font-display: auto;
	src: url(../fonts/Dosis-normal-500.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Dosis';
	font-style: normal;
	font-weight: 600;
	font-display: auto;
	src: url(../fonts/Dosis-normal-600.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: local('Lato Regular'), local('Lato-Regular'), url(../fonts/Lato-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-display: auto;
	src: local('Open Sans Light'), local('OpenSans-Light'), url(../fonts/Open_Sans-normal-300.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: local('Open Sans Regular'), local('OpenSans-Regular'), url(../fonts/Open_Sans-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-display: auto;
	src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(../fonts/Open_Sans-normal-600.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-display: auto;
	src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../fonts/Open_Sans-normal-700.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(../fonts/Work_Sans-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 700;
	font-display: auto;
	src: url(../fonts/Work_Sans-normal-700.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

